<template>
  <div>
    <slot
      :title="$t('devlanding.page-title')"
    ></slot>
    <div class="content-wrapper">
      <section class="content-section text-center">
        <div class="dev-discontinued">
          <p><strong>{{ $t('devdiscontinued.headline') }}</strong></p>
          <p v-html="$t('devdiscontinued.text1')"></p>
        </div>
        <h1>{{ $t('devlanding.headline') }}</h1>
        <p class="lead">{{ $t('devlanding.text1') }}</p>
      </section>
      <div class="article-grid news">
        <article class="article-item ">
          <a
            :href="$t('devlanding.tile_left_url')"
            target="_blank"
            class="article-link"
          >
            <div
              class="article-content"
              data-equalizer-watch=""
            >
              <div class="article-image">
                <img src="../common/assets/images/devkit.jpg" :alt="$t('alt_text.dev_kit')">
                <div class="article-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" class="injected-svg inject-svg">
                    <g fill="none" fill-rule="evenodd">
                      <circle class="background-shape" cx="25" cy="25" r="25" fill="#FFF"></circle>
                      <path d="M32 28v6H17V19h6m11-2h-7m7 0v7m-8.453 1.583l8.485-8.486" class="icon-strokes" stroke="#2D2D37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="article-description">
                <header class="article-heading">
                  <h2 class="article-title">
                    <span>{{ $t('devlanding.tile_left_headline') }}</span>
                  </h2>
                  <h3 class="surf">
                    {{ $t('devlanding.tile_left_text') }}
                  </h3>
                </header>
              </div>
            </div>
          </a>
        </article>
        <article class="article-item ">
          <a
            :href="$t('devlanding.tile_mid_url')"
            target="_blank"
            class="article-link"
          >
            <div
              class="article-content"
              data-equalizer-watch=""
            >
              <div class="article-image">
                <img src="../common/assets/images/get-started.jpg" :alt="$t('alt_text.get_started')">
                <div class="article-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" class="injected-svg inject-svg">
                    <g fill="none" fill-rule="evenodd">
                      <circle class="background-shape" cx="25" cy="25" r="25" fill="#FFF"></circle>
                      <path d="M32 28v6H17V19h6m11-2h-7m7 0v7m-8.453 1.583l8.485-8.486" class="icon-strokes" stroke="#2D2D37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="article-description">
                <header class="article-heading">
                  <h2 class="article-title">
                    <span>{{ $t('devlanding.tile_mid_headline') }}</span>
                  </h2>
                  <h3 class="surf">{{ $t('devlanding.tile_mid_text') }}</h3>
                </header>
              </div>
            </div>
          </a>
        </article>
        <article class="article-item ">
          <a
            :href="$t('devlanding.tile_right_url')"
            target="_blank"
            class="article-link"
          >
            <div
              class="article-content"
              data-equalizer-watch=""
            >
              <div class="article-image">
                <img src="../common/assets/images/forum.jpg" :alt="$t('alt_text.forum')">
                <div class="article-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" class="injected-svg inject-svg">
                    <g fill="none" fill-rule="evenodd">
                      <circle class="background-shape" cx="25" cy="25" r="25" fill="#FFF"></circle>
                      <path d="M32 28v6H17V19h6m11-2h-7m7 0v7m-8.453 1.583l8.485-8.486" class="icon-strokes" stroke="#2D2D37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="article-description">
                <header class="article-heading">
                  <h2 class="article-title">
                    <span>{{ $t('devlanding.tile_right_headline') }}</span>
                  </h2>
                  <h3 class="surf">{{ $t('devlanding.tile_right_text') }}</h3>
                </header>
              </div>
            </div>
          </a>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
  import auth from '../common/auth';

  export default {
    name: 'dev-landing',
    data() {
      return {
        user: auth.user,
      };
    },
  };
</script>

<style>
  .dev-discontinued {
    max-width: 60%;
    margin: 0 auto;
    font-family: 'UniSansBook';
    font-size: 18px;
  }
  
  .devlanding__container {
    width: 90%;
    margin: 40px auto;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .UniSansBook-loaded .devlanding__container {
    font-family: 'UniSansBook';
  }

  .devlanding__container a {
    color: #00c8e6;
  }

  @media all and (min-width: 640px) {
    .devlanding__container {
      width: 75%;
      margin: 100px auto;
    }
    .devlanding__container .devlanding__content h1 {
      font-size: 36px;
    }
  }

  @media all and (min-width: 1024px) {
    .devlanding__container {
      width: 60%;
    }
    .devlanding__container .devlanding__content {
      float: left;
    }
  }

  .devlanding__box {
    float: left;
    position: relative;
    width: 30%;
    padding-bottom: 30%;
    /* = width for a 1:1 aspect ratio */
    margin: 1.66%;
    overflow: hidden;
    background-color: white;
  }

  .devlanding__box:hover {
    background-color: #00C8E6;
  }

  .surf {
    font-size:11pt;
    padding-top: 3pt;
  }

  .devlanding__box_content {
    position: absolute;
    height: 80%;
    /* = 100% - 2*10% padding */
    width: 90%;
    /* = 100% - 2*5% padding */
    padding: 10% 5%;
    text-align: center;
  }
  /*Main styles*/

  .content-section {
    padding-top: 40px;
  }

  h1, p {
    text-align: center;
  }

  h6 {
    font-family: 'UniSansBook';
    color: #444354;
    font-size: 0.9rem;
    margin-top: 4px;
    font-weight: 200;
  }

  .section-title {
    font-size: 2.5rem;
    font-family: UniSansBook;
    font-weight: 400;
    font-style: normal;
    color: inherit;
    text-rendering: optimizeLegibility;
    margin-top: 0;
    margin-bottom: .5rem;
    line-height: 1.2;
    text-align: center;
  }

  .section-subtitle {
    font-family: UniSansBook;
    font-weight: 300;
    font-size: 1.375rem;
    color: #00c8e6;
    margin-bottom: 20px;
    text-align: center;
  }

  .lead {
    font-size: 1.125rem;
    line-height: 1.6;
    font-weight: 300;
    padding-left: 200px;
    padding-right: 200px;
    text-align: center;
  }

  /*Article grid styles begin*/

  .article-grid {
    padding: 60px;
    padding-top: 15px;
  }

  .article-grid>.article-item {
    width: 33.33333%;
    float: left;
  }

  .article-grid>.article-item:nth-of-type(1n) {
    clear: none;
  }

  .article-grid>.article-item:nth-of-type(3n+1) {
    clear: both;
  }

  .article-grid>.article-item:last-child {
    float: left;
  }

  .article-grid:after,
  .article-grid:before {
    content: '';
    display: table;
  }

  .article-grid:after {
    clear: both;
  }

  .article-grid.two-columns>.article-item {
    width: 50%;
    float: left;
  }

  .article-grid.two-columns>.article-item:nth-of-type(1n) {
    clear: none;
  }

  .article-grid.two-columns>.article-item:nth-of-type(2n+1) {
    clear: both;
  }

  .article-grid.two-columns>.article-item:last-child {
    float: left;
  }

  .article-grid .article-image {
    position: relative;
  }

  .article-grid .article-image img {
    width: 100%;
  }

  .article-grid .article-link {
    display: block;
    color: currentColor;
  }

  .article-grid .article-link .article-button {
    position: absolute;
    bottom: -22px;
    right: 20px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, .12);
  }

  .article-grid .article-link .article-button svg {
    max-height: 100%;
  }

  .article-grid .article-link .article-button svg * {
    transition: all .2s ease-out;
  }

  .article-grid .article-link:hover .article-button .background-shape {
    fill: #2d2d37;
  }

  .article-grid .article-link:hover .article-button .icon-strokes,
  .article-grid .article-link:hover .article-button .icon-strokes * {
    stroke: #fefefe;
  }

  .article-grid .article-link:hover .article-button .icon-fills,
  .article-grid .article-link:hover .article-button .icon-fills * {
    fill: #fefefe;
  }

  .article-grid .article-item {
    padding: 1rem;
  }

  .article-grid .article-item .contextual {
    top: 0 !important;
  }

  .article-grid .article-description {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-top: 40px;
  }

  .article-grid .article-description .learn-more {
    font-size: .9375rem;
    color: #00c8e6;
    transition: all .2s ease-out;
  }

  .article-grid .article-description .arrow {
    display: inline-block;
    margin-left: 2px;
  }

  .article-grid .article-description.has-footer {
    padding-bottom: 80px;
  }

  .article-grid .article-heading {
    margin-bottom: 20px;
  }

  .article-grid .article-heading .article-date {
    margin: 0 0 10px;
  }

  .article-grid .article-body p:last-child {
    margin: 0;
  }

  .article-grid .article-content {
    position: relative;
    background: #fff;
    border-radius: 3px;
    transition: box-shadow .2s linear 0s, transform .2s linear 0s;
    overflow: hidden;
  }

  .article-grid .article-content:hover {
    box-shadow: 0 8px 16px 0 rgba(45, 45, 55, .25);
    transform: translateY(-10px);
  }

  .article-grid .article-content footer {
    position: absolute;
    bottom: 0;
    left: 30px;
    height: 90px;
  }

  @media screen and (max-width:63.9375em) {
    .article-grid>.article-item {
      width: 50%;
      float: left;
    }
    .article-grid>.article-item:nth-of-type(1n) {
      clear: none;
    }
    .article-grid>.article-item:nth-of-type(2n+1) {
      clear: both;
    }
    .article-grid>.article-item:last-child {
      float: left;
    }

    .lead {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media screen and (max-width:47.9375em) {
    .article-grid.two-columns>.article-item,
    .article-grid>.article-item {
      width: 100%;
      float: left;
    }
    .article-grid.two-columns>.article-item:nth-of-type(1n),
    .article-grid>.article-item:nth-of-type(1n) {
      clear: none;
    }
    .article-grid.two-columns>.article-item:nth-of-type(1n+1),
    .article-grid>.article-item:nth-of-type(1n+1) {
      clear: both;
    }
    .article-grid.two-columns>.article-item:last-child,
    .article-grid>.article-item:last-child {
      float: left;
    }
  }
</style>
