<template>
  <div>
    <slot v-if="$te(paramShow + '.page_title')" :title="$t(paramShow + '.page_title')"></slot>
    <slot v-else title=""></slot>
    <div class="content-wrapper">
      <div class="landing__content">
        <h1 v-if="user.authenticated && $te(paramShow + '.headline_authenticated')">
          {{ $t(paramShow + '.headline_authenticated', { username: user.name }) }}
        </h1>
        <h1 v-else-if="$te(paramShow + '.headline')">{{ $t(paramShow + '.headline') }}</h1>
        <p v-if="$te(paramShow + '.text1')" class="lead-user-info" v-html="$t(paramShow + '.text1')"></p>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRoute } from 'vue-router';
  import auth from '../common/auth';

  export default {
    name: 'user-info',
    setup() {
      const { user } = auth;
      const route = useRoute();
      let paramShow = route.query.show;

      if (paramShow !== undefined) {
        paramShow = `${route.name}.${paramShow}`;
        return { user, paramShow };
      }
      window.location.href = '/404.html';

      return {};
    },
  };
</script>

<style scoped>
#content-container .content-wrapper {
    background: #f3f3f3 url('../assets/images/svg/user-info-bg.svg') no-repeat bottom center;
    background-size: contain;
    min-height: 730px;
    border-radius: 0 0 4px 4px;
}

.landing__content {
  margin: 40px auto;
  width: 60%;
  padding-left: 0 !important;
}

@media all and (max-width: 480px) {
  .content-container, .content-wrapper {
    min-height: 80vh !important;
    border-radius: 0 !important;
  }
}
@media all and (max-width: 640px) {
  .landing__content {
    margin-top: 40px;
    width: 95%;
    padding-left: 5px;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 3000;
  }
}

@media all and (min-width: 640px) {
  .landing__content {
    margin-top: 40px;
    width: 95%;
    padding-left: 10px;
  }

  .landing__container .landing__content h1 {
    font-size: 36px;
  }
}

@media all and (min-width: 1024px) {
  .landing__container {
    width: 60%;
  }
  .landing__container .landing__content {
    float: left;
  }

  .landing__content {
    margin-top: 40px;
    width: 60%;
    padding-left: 20px;
  }
}
@media all and (min-width: 1300px) {
  .landing__container .landing__content {
    float: left;
  }
}

.lead-user-info {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 300;
  text-align: center;
  padding: 0 !important;
}

.lead-user-info strong {
  font-weight: 500;
}

.lead-user-info p {
  text-align: left;
}
</style>
