<template>
  <form aria-live="polite" class="settings__form" id="js-email-form">
    <fieldset>
      <legend>
        <h2 class="form__title">
          {{ $t('myaccount.email.header') }}
        </h2>
      </legend>
      <div class="explanation-alert" id="explanation-alert">
        <p>{{ $t('myaccount.email.info-box') }}</p>
      </div>
      <!-- myaccount.email.bosch-id-disclaimer -->
      <label for="email" class="field email-container clearfix">
        <span class="field__label">
          {{ $t('myaccount.email.email') }}
          <sup v-if="disableCobiSettings">*</sup>
        </span>
        <input
          class="field__input"
          type="email"
          id="email"
          v-model.trim="fields.email"
          @keyup="emailFieldAnimation"
          :disabled="disableCobiSettings"
        />
        <span class="field__error"></span>
      </label>
      <div id="email-edit-state">
        <label for="email-repeat" class="field clearfix">
          <span class="field__label">
            {{ $t('myaccount.email.email-confirm') }}
          </span>
          <input
            class="field__input"
            type="text"
            id="email_repeat"
            v-model.trim="fields.emailRepeat"
            @keyup="emailRepeatAnimation"
          />
          <span class="field__error"></span>
        </label>
        <label for="js-password" class="field clearfix">
          <span class="field__label">
            {{ $t('myaccount.email.password') }}
          </span>
          <input
            class="field__input"
            type="password"
            id="js-password"
            v-model="fields.currentPassword"
            @keyup="passwordFieldAnimation"
            required
          />
          <span class="field__error"></span>
        </label>
      </div>
      <div class="settings__form__footer clearfix">
        <button
          v-if="!disableCobiSettings"
          disabled
          id="js-email-save-button"
          type="submit"
          @mouseenter="buttonHoverAnimation"
          @mouseleave="buttonHoverAnimation"
          @click.prevent="buttonClick"
          class="button settings__form__save-button spinning-button button--filled--blue"
        >
          <span class="spinner"></span>
          <span class="button-label">
            {{ $t('myaccount.email.button') }}
          </span>
        </button>
      </div>
      <p class="required" v-if="disableCobiSettings" style="margin-top: 0">
        <sup>*</sup>
        {{ $t('myaccount.email.singlekey_id_disclaimer') }}
      </p>
    </fieldset>
  </form>
</template>

<script>
  import emitter from '../../utils/emitter';
  import auth from '../common/auth';

  export default {
    name: 'user-email',
    data() {
      return {
        user: auth.user,
        disableCobiSettings: auth.user.isBosch === true,
        fields: {
          email: '',
          emailRepeat: '',
          currentPassword: '',
        },
      };
    },
    methods: {
      async updateEmail(email, currentPassword) {
        try {
          const data = {
            newEmail: email,
            currentPassword,
          };

          const res = await auth.doAuthenticatedPut('account/email', data);

          return res.data;
        } catch (err) {
          return err.response.data.errors[0];
        }
      },
      displaySuccessMessage() {
        emitter.emit('display-success-message');

        const explanationAlert = document.querySelector('#explanation-alert');
        explanationAlert.style.display = 'block';

        const emailEditState = document.querySelector('#email-edit-state');
        emailEditState.style.display = 'none';
      },
      displayErrorMessage() {
        emitter.emit('display-error-message');
      },
      showFieldErrorMsg(inputField, msgText) {
        // given the specific input field, the following two functions
        // will show or hide the error message for that field
        // and will populate it with the text supplied in the argument list
        // the text for the same input field might differ depending on kind of error,
        // that's why the text is supplied as argument

        const parent = inputField.closest('label');

        const fieldError = parent.querySelector('.field__error');
        fieldError.style.display = 'block';
        fieldError.textContent = msgText;
        inputField.classList.add('error');
      },
      hideFieldErrorMsg(inputField) {
        const parent = inputField.closest('label');
        const fieldError = parent.querySelector('.field__error');
        fieldError.textContent = '';
        fieldError.style.display = 'none';
        inputField.classList.remove('error');
      },
      fillFields() {
        this.fields.email = this.user.email;
      },
      emailFieldAnimation() {
        // if the user types in the field, this indicates A CHANGE has been made
        // and the button turns blue

        // if the email is not empty but the user entered the same email again,
        // therefore NO CHANGE is made
        const emailEditState = document.querySelector('#email-edit-state');

        if (this.fields.email && this.fields.email === this.user.email) {
          emailEditState.style.display = 'none';
        } else {
          emailEditState.style.display = 'block';
        }

        const email = document.querySelector('#email');
        const emailRepeat = document.querySelector('#email_repeat');

        // live error validation
        if (this.fields.email && !this.isValidEmailAddress()) {
          this.showFieldErrorMsg(
            email,
            this.$i18n.t('messages.INVALID_EMAIL_ADDRESS_MSG')
          );
        } else {
          this.hideFieldErrorMsg(email);
        }

        if (!this.fields.emailRepeat) {
          this.hideFieldErrorMsg(emailRepeat);
        } else {
          if (!this.emailMatch()) {
            this.showFieldErrorMsg(
              emailRepeat,
              this.$i18n.t('messages.EMAIL_DONT_MATCH_MSG')
            );
          } else {
            this.hideFieldErrorMsg(emailRepeat);
          }

          const saveButton = document.querySelector('#js-email-save-button');
          if (this.fields.currentPassword && this.emailMatch()) {
            saveButton.removeAttribute('disabled');
          } else {
            saveButton.setAttribute('disabled', '');
          }
        }
      },
      emailRepeatAnimation() {
        const emailInput = document.querySelector('#email');
        const emailRepeatInput = document.querySelector('#email_repeat');

        // live error validation
        if (this.fields.emailRepeat && !this.isValidEmailAddress()) {
          this.showFieldErrorMsg(
            emailInput,
            this.$i18n.t('messages.INVALID_EMAIL_ADDRESS_MSG')
          );
        } else {
          this.hideFieldErrorMsg(emailInput);
        }

        if (!this.fields.emailRepeat) {
          this.hideFieldErrorMsg(emailRepeatInput);
        } else {
          if (!this.emailMatch()) {
            this.showFieldErrorMsg(
              emailRepeatInput,
              this.$i18n.t('messages.EMAIL_DONT_MATCH_MSG')
            );
          } else {
            this.hideFieldErrorMsg(emailRepeatInput);
          }

          const saveButton = document.querySelector('#js-email-save-button');
          if (this.fields.currentPassword && this.emailMatch()) {
            saveButton.removeAttribute('disabled');
          } else {
            saveButton.setAttribute('disabled', '');
          }
        }
      },
      passwordFieldAnimation() {
        const saveButton = document.querySelector('#js-email-save-button');
        if (
          this.fields.currentPassword
          && this.emailMatch()
          && this.isValidEmailAddress()
        ) {
          saveButton.removeAttribute('disabled');
        } else {
          saveButton.setAttribute('disabled', '');
        }
      },
      isValidEmailAddress() {
        const filter = /\S+@\S+\.\S+/;

        return filter.test(this.fields.email);
      },
      emailMatch() {
        return this.fields.email === this.fields.emailRepeat;
      },
      buttonHoverAnimation(event) {
        const button = event.target;

        if (button.classList.contains('loading')) return;

        button.classList.toggle('hover');
      },

      async buttonClick() {
        try {
          const emailInput = document.querySelector('#email');
          if (!this.fields.email) {
            this.showFieldErrorMsg(
              emailInput,
              this.$i18n.t('messages.MISSING_EMAIL_ADDRESS_MSG')
            );
          }

          if (this.isValidEmailAddress()) {
            const saveButton = document.querySelector('#js-email-save-button');
            saveButton.setAttribute('disabled', 'disabled');

            const response = await this.updateEmail(
              this.fields.email,
              this.fields.currentPassword
            );

            if (response.error === 'EMAIL_ADDRESS_IN_USE') {
              this.showFieldErrorMsg(
                emailInput,
                this.$i18n.t('messages.EMAIL_ADDRESS_IN_USE_MSG')
              );
            } else if (response.error === 'email invalid') {
              this.showFieldErrorMsg(
                emailInput,
                this.$i18n.t('messages.INVALID_EMAIL_ADDRESS_MSG')
              );
            } else if (response.error === 'INVALID_EMAIL_PROVIDER') {
              this.showFieldErrorMsg(
                emailInput,
                this.$i18n.t('messages.INVALID_EMAIL_PROVIDER_MSG')
              );
            } else if (response.error === 'MISSING_EMAIL_ADDRESS') {
              this.showFieldErrorMsg(
                emailInput,
                this.$i18n.t('messages.MISSING_EMAIL_ADDRESS_MSG')
              );
            } else {
              this.displaySuccessMessage();
              const emailForm = document.querySelector('#js-email-form');
              emailForm.reset();
            }
          }
        } catch (error) {
          this.displayErrorMessage();
        }
      },
    },
    mounted() {
      this.fillFields();
    },
  };
</script>

<style>
.required {
  text-align: left;
  font-size: 14px;
  color: #aaa;
  font-weight: 200;
}

.field__label sup {
  font-size: 12px;
}

.explanation-alert {
  background-color: #00c8e6;
  color: white;
  text-align: center;
  padding: 15px 15px 15px 15px;
  font-family: 'UniSansBook';
  font-size: 16px;
  border-radius: 5px;
  float: left;
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
  display: none;
}

.explanation-alert p {
  text-align: left;
  font-weight: 400;
  margin: 0;
}

.explanation-alert svg {
  float: left;
}

#email-edit-state {
  display: none;
}

fieldset {
  border: 0;
}
</style>
