<template>
  <div
    class="profile-actions profile-actions--footer"
    data-test-id="manage-bosch-id-section"
  >
    <h2 class="form__title">
      {{ $t('myaccount.manage-singlekey_id.header')}}
    </h2>
    <p class="lead-settings">
      {{ $t('myaccount.manage-bosch-id.explanation')}}
    </p>
    <div class="cta-wrapper">
      <a
        v-bind:href="boschProfileLink"
        class="button button--ghost--tarmac spinning-button"
      >
        {{ $t('myaccount.manage-singlekey_id.button')}}
      </a>
    </div>
  </div>
</template>

<script>
  import { CIAM_PROFILE_URI } from '../../config';

  export default {
    name: 'user-manage-bosch-id',
    computed: {
      boschProfileLink() {
        return CIAM_PROFILE_URI;
      },
    },
  };
</script>

<style scoped>
  .lead-settings {
    float: left;
    width: 100%;
    margin-bottom: 3rem;
    text-align: left;
  }
  .cta-wrapper {
    width: 100%;
    float: left;
  }

  .button {
    color: #2D2D37 !important;
  }

  .button:hover,
  .button:focus {
    color: white !important;
    background-color: #2D2D37 !important;
    border: 1.5px solid #2D2D37 !important;
  }
</style>
