<template>
  <div>
    <slot
      :title="$t(`bosch-id-faq.${version}.page-title`)"
    ></slot>
    <div class="content-wrapper">
      <div class="faq__container clearfix">
        <h1>{{ $t(`bosch-id-faq.${version}.headline`) }}</h1>
        <p class="about-bosch-id">
          {{ $t(`bosch-id-faq.${version}.description`) }}
        </p>
        <div class="bosch-id-faq">
          <ul class="accordion">
            <bosch-id-faq
              v-for="(question, index) in $tm(`bosch-id-faq.${this.version}.questions`)"
              :key="index"
              :faqItem="question"
              :id="index+1"
              :accordionList="accordionList"
              :toggleAccordion="toggleAccordion"
            ></bosch-id-faq>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BoschIdFaq from 'Components/BoschIdFaq.vue';

  export default {
    name: 'faq',
    props: ['version'],
    data() {
      return {
        accordionList: [],
      };
    },
    components: {
      'bosch-id-faq': BoschIdFaq,
    },
    methods: {
      toggleAccordion(index) {
        const item = this.accordionList.indexOf(index);
        // handle accordion state
        if (item !== -1) {
          // removes inactive accordions
          return this.accordionList.splice(item, 1);
        }
        // keeps actice accordion
        this.accordionList.push(index);
        return null;
      },
    },
  };
</script>

<style scoped>
.about-bosch-id {
  font-size: 1.125rem;
  font-weight: 300;
}

.accordion {
  margin: 30px 0;
  border: 0;
  list-style-type: none;
  float: left;
  width: 100%;
}

.accordion li {
  margin: 0 0 2px;
  font-size: inherit;
}

.accordion span {
  color: #2d2d37;
}

.faq__container {
  width: 90%;
  margin: 40px auto;
  font-size: 18px;
  font-family: "UniSansBook";
}

.faq__container .field {
  margin: 30px 0 40px;
}

.faq__container a {
  color: #00c8e6;
}

.faq__container p {
  text-align: left;
}

@media all and (min-width: 640px) {
  .faq__container {
    width: 75%;
    margin: 100px auto;
  }
  .faq__container h1 {
    font-size: 36px;
  }
}
@media all and (min-width: 1024px) {
  .faq__container {
    width: 60%;
  }
  .faq__container .privacypolicy__content {
    width: 80%;
    float: left;
  }
}
@media all and (min-width: 1300px) {
  .faq__container .privacypolicy__content {
    width: 60%;
    float: left;
  }
}
</style>
