<template>
  <form aria-live="polite" class="settings__form" id="js-profile-form">
    <fieldset>
      <legend>
        <h2 class="form__title">
          {{ $t('myaccount.general-data.header') }}
        </h2>
      </legend>
      <label for="first-name" class="field clearfix">
        <span class="field__label">
          {{ $t('myaccount.general-data.first-name') }}
          <sup>*</sup>
        </span>
        <input
          class="field__input"
          type="text"
          id="first-name"
          v-model.trim="fields.firstname"
          @keyup="firstNameAnimation"
          @keydown.enter.prevent
        />
        <span class="field__error"></span>
      </label>
      <label for="last-name" class="field clearfix">
        <span class="field__label">
          {{ $t('myaccount.general-data.last-name') }}
        </span>
        <input
          class="field__input"
          type="text"
          id="last-name"
          v-model.trim="fields.lastname"
          @keyup="lastNameAnimation"
          @keydown.enter.prevent
        />
        <span class="field__error"></span>
      </label>
      <label for="country" class="field clearfix">
        <span class="field__label">
          {{ $t("myaccount.general-data.country") }}
        </span>
        <select id="country" class="field__input" v-model="fields.selectedCountry" @change="countryAnimation">
          <option disabled :selected="user.country === null" :value="null" hidden>{{ $t("myaccount.general-data.country-placeholder") }}</option>
          <option v-for="(country, index) in countriesList" v-bind:key="index" :value="country" :selected="fields.selectedCountry">
              {{ country.countryName }}
          </option>
        </select>
        <span class="field__error"></span>
      </label>
      <div class="settings__form__footer clearfix">
        <button
          disabled
          id="js-profile-save-button"
          type="submit"
          class="button settings__form__save-button spinning-button button--filled--blue"
          @mouseenter="buttonHoverAnimation"
          @mouseleave="buttonHoverAnimation"
          @click.prevent="buttonClick"
        >
          <span class="spinner"></span>
          <span class="button-label">
            {{ $t('myaccount.general-data.button') }}
          </span>
        </button>
      </div>
      <p class="required">
        <sup>*</sup>
        {{ $t('myaccount.general-data.required') }}
      </p>
    </fieldset>
  </form>
</template>

<script>
  import auth from '../common/auth';
  import emitter from '../../utils/emitter';

  export default {
    name: 'user-data',
    data() {
      return {
        user: auth.user,
        fields: {
          firstname: '',
          lastname: '',
          selectedCountry: {},
        },
        isFirstNameChanged: false,
        isCountryChanged: false,
        countriesList: [],
      };
    },
    methods: {
      async updateName(first, last) {
        const data = {
          firstname: first,
          lastname: last || null,
        };

        const res = await auth.doAuthenticatedPut('account/general', data);
        if (res.data) {
          return res.data;
        }

        throw new Error();
      },
      async updateCountry(country) {
        const data = { country };
        const res = await auth.doAuthenticatedPut('account/country', data);

        if (res.data) {
          return res.data;
        }

        throw new Error();
      },
      firstNameChanged() {
        return this.fields.firstname !== this.user.name;
      },
      lastNameChanged() {
        return this.fields.lastname !== this.user.lastname;
      },
      countryChanged() {
        return this.fields.selectedCountry.countryCode !== this.user.country;
      },
      async fillFields() {
        this.fields.firstname = this.user.name;
        this.fields.lastname = this.user.lastname;

        await this.getCountriesList();
        const country = await this.countriesList.find((item) => item.countryCode.toLowerCase() === this.user.country);
        if (this.user.country !== null) {
          this.fields.selectedCountry = await country;
        } else {
          this.fields.selectedCountry = null;
        }
      },
      async getCountriesList() {
        const { locale } = this.$i18n;
        const list = await auth.doAuthenticatedGet(`hc-terms-and-conditions/all?lang=${locale}`);
        this.countriesList = await list;
      },
      displaySuccessMessage() {
        emitter.emit('display-success-message');
      },
      displayErrorMessage() {
        emitter.emit('display-error-message');
      },
      showFieldErrorMsg(inputField, msgText) {
        // given the specific input field, the following two functions
        // will show or hide the error message for that field
        // and will populate it with the text supplied in the argument list
        // the text for the same input field might differ depending on kind of error,
        // that's why the text is supplied as argument

        const parent = inputField.closest('label');

        const fieldError = parent.querySelector('.field__error');
        fieldError.style.display = 'block';
        fieldError.textContent = msgText;
        inputField.classList.add('error');
      },
      hideFieldErrorMsg(inputField) {
        const parent = inputField.closest('label');
        const fieldError = parent.querySelector('.field__error');
        fieldError.textContent = '';
        fieldError.style.display = 'none';
        inputField.classList.remove('error');
      },
      firstNameAnimation() {
        const saveButton = document.querySelector('#js-profile-save-button');

        if (this.firstNameChanged()) {
          this.isFirstNameChanged = true;

          // if the user types in the field, this indicates A CHANGE has been made
          // and the button turns blue placing this condition inside this if statement
          // ensures that adding white space before or after the current
          // value does not trigger a change—the value is still considered the same
          saveButton.removeAttribute('disabled');
          saveButton.classList.remove('hover');
        } else if (!this.firstNameChanged() && !this.lastNameChanged()) {
          this.isFirstNameChanged = false;
          saveButton.setAttribute('disabled', '');
        }

        // if the field is not empty but the user entered the same value again,
        // therefore NO CHANGE is made the button is disabled UNLESS one
        // of the other two fields is changed
        if (
          this.fields.firstname
          && !this.firstNameChanged()
          && !this.lastNameChanged()
        ) {
          saveButton.setAttribute('disabled', '');
        }

        const firstNameInput = document.querySelector('#first-name');
        if (this.fields.firstname) {
          this.hideFieldErrorMsg(firstNameInput);
        }
        if (this.fields.firstname === '') {
          this.showFieldErrorMsg(
            firstNameInput,
            this.$i18n.t('messages.MISSING_FIRST_NAME_MSG')
          );
          saveButton.setAttribute('disabled', '');
        }
      },
      lastNameAnimation() {
        const saveButton = document.querySelector('#js-profile-save-button');

        if (
          this.fields.lastname !== this.user.lastname
          && this.fields.firstname !== ''
        ) {
          // if the user types in the field, this indicates A CHANGE has been made
          // and the button turns blue placing this condition inside this if statement
          // ensures that adding white space before or after the current
          // value does not trigger a change—the value is still considered the same
          saveButton.removeAttribute('disabled');
          saveButton.classList.remove('hover');
        } else {
          saveButton.setAttribute('disabled', '');
        }

        // if the field is not empty but the user entered the same value again,
        // therefore NO CHANGE is made the button is disabled UNLESS one
        // of the other two fields is changed
        if (
          this.fields.lastname
          && this.fields.lastname === this.user.lastname
          && !this.firstNameChanged()
        ) {
          saveButton.setAttribute('disabled', '');
        }

        if (this.fields.lastname) {
          const lastNameInput = document.querySelector('#last-name');
          this.hideFieldErrorMsg(lastNameInput);
        }
      },
      countryAnimation() {
        const saveButton = document.querySelector('#js-profile-save-button');

        if (
          this.fields.selectedCountry.countryCode !== this.user.country
        ) {
          saveButton.removeAttribute('disabled');
          saveButton.classList.remove('hover');
        } else {
          saveButton.setAttribute('disabled', '');
        }

        if (this.fields.selectedCountry && this.fields.selectedCountry.countryCode === this.user.country && !this.countryChanged()
        ) {
          saveButton.setAttribute('disabled', '');
        }

        if (this.fields.selectedCountry) {
          const countryInput = document.querySelector('#country');
          this.hideFieldErrorMsg(countryInput);
        }
      },
      buttonHoverAnimation(event) {
        const form = event.target;

        if (form.classList.contains('loading')) return;

        form.classList.toggle('hover');
      },
      async buttonClick() {
        const button = document.querySelector('#js-profile-save-button');

        if (!this.fields.firstname) {
          const firstNameInput = document.querySelector('#first-name');
          this.showFieldErrorMsg(
            firstNameInput,
            this.$i18n.t('messages.MISSING_FIRST_NAME_MSG')
          );
        }

        if (this.fields.firstname && this.fields.selectedCountry) {
          button.setAttribute('disabled', 'disabled');
          const response = await this.updateName(
            this.fields.firstname,
            this.fields.lastname
          ).then(await this.updateCountry(this.fields.selectedCountry.countryCode.toLowerCase()));

          this.user.name = this.fields.firstname;
          this.user.lastname = this.fields.lastname;
          this.user.country = this.fields.selectedCountry.countryCode.toLowerCase();

          if (response.code !== 3000012) {
            this.displayErrorMessage();
          } else {
            this.displaySuccessMessage();
          }
        }
      },
    },
    async mounted() {
      await this.fillFields();
    },
  };
</script>

<style>
.required {
  text-align: left;
  font-size: 14px;
  color: #aaa;
  font-weight: 200;
}

.field__label sup {
  font-size: 12px;
}

fieldset {
  border: 0;
}
</style>
