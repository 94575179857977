<template>
  <div>
    <slot
      title="Build Information"
    ></slot>
    <div class="wrapper">
      <div
        class="content"
        role="table"
        aria-label="Build Information"
        aria-describedby="build-information-caption"
      >
        <div id="build-information-caption">Build information</div>
        <div class="row" role="row">
          <div class="field header" role="rowheader">
            Version
          </div>
          <div class="field" role="cell">
            {{ version }}
          </div>
        </div>
        <div class="row" role="row">
          <div class="field header" role="rowheader">
            Build Date
          </div>
          <div class="field" role="cell">
            {{ buildDate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import buildInformation from '../../buildInformation.json';

  export default {
    name: 'build-information',
    computed: {
      version() {
        return buildInformation.version;
      },
      buildDate() {
        return buildInformation.buildDate;
      },
    },
  };
</script>
<style scoped>
    .wrapper {
        background-color: #fff;
        margin-top: 110px;
        min-height: 800px;
    }
    .content {
        background-color: #f3f3f3;
        margin: 20px auto;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .field {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;
      padding: 0 20px;
    }

    .header {
      font-weight: bold;
      justify-content: flex-end;
    }

    #build-information-caption {
      visibility: hidden;
    }
</style>
