import Terms from 'Pages/Terms.vue';
import PrivacyPolicy from 'Pages/PrivacyPolicy.vue';
import Faq from 'Pages/Faq.vue';
import ServiceDescription from 'Pages/ServiceDescription.vue';
import CorporateInformation from 'Pages/CorporateInformation.vue';

export const cobiLegalRoutes = [
  {
    path: '/terms',
    redirect: { name: 'terms/v2' },
  },
  {
    path: '/terms/v1',
    redirect: { name: 'terms/v2' },
  },
  {
    path: '/terms/v2',
    name: 'terms/v2',
    component: Terms,
  },
  {
    path: '/privacy-policy',
    redirect: { name: 'privacy-policy/v3' },
  },
  {
    path: '/privacy-policy/v1',
    redirect: { name: 'privacy-policy/v3' },
  },
  {
    path: '/privacy-policy/v2',
    redirect: { name: 'privacy-policy/v3' },
  },
  {
    path: '/privacy-policy/v3',
    component: PrivacyPolicy,
    name: 'privacy-policy/v3',
  },
  {
    path: '/faq/',
    name: 'faq',
    component: Faq,
    props: { version: 'v1' },
  },
];

export const boschIdLegalRoutes = [
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/terms/v1',
    redirect: { name: 'terms' },
  },
  {
    path: '/terms/v2',
    redirect: { name: 'terms' },
  },
  {
    path: '/terms/v3',
    redirect: { name: 'terms' },
  },
  {
    path: '/privacy-policy',
    name: 'privacypolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/privacy-policy/v1',
    redirect: { name: 'privacypolicy' },
  },
  {
    path: '/privacy-policy/v2',
    redirect: { name: 'privacypolicy' },
  },
  {
    path: '/privacy-policy/v3',
    redirect: { name: 'privacypolicy' },
  },
  {
    path: '/privacy-policy/v4',
    redirect: { name: 'privacypolicy' },
  },
  {
    path: '/privacy-policy/v5',
    redirect: { name: 'privacypolicy' },
  },
  {
    path: '/privacy-policy/v6',
    redirect: { name: 'privacypolicy' },
  },
  {
    path: '/faq/',
    name: 'faq',
    component: Faq,
    props: { version: 'v1' },
  },
  {
    path: '/service-description',
    name: 'sesrvicedescription',
    component: ServiceDescription,
    alias: '/service-description/v1',
  },
  {
    path: '/corporate-information',
    name: 'corporate_information',
    component: CorporateInformation,
  },
];
