<template>
  <div id="app">
  <ul class="skip-links" :class="{'skip-links-active': focused}" tabindex=1>
    <li>
      <a href="#content-container" tab-index=0 ref="skipLink" @focus="focused = true" @blur="focused = false">Skip to main content</a>
    </li>
  </ul>
    <cobi-header
      :navigation="[
        {
          translation: 'header.settings',
          link: '/settings',
          requireAuthentication: true,
        },
        {
          translation: 'header.developer',
          link: '/developer',
          requireAuthentication: true,
        },
      ]"
      :link-to-account="true"
    ></cobi-header>
    <div
      class="wrapper content-container clearfix"
      id="content-container"
      role="main"
    >
      <router-view v-slot="{ Component }">
        <component :is="Component">
          <template v-slot:default="slotProps">
            <div class="content-header_padding">
              <div id="content-header_wrapper">
                <success-notification
                  v-if="slotProps.withSuccess"
                ></success-notification>
                <error-notification
                  v-if="slotProps.withError"
                  :error="slotProps.error"
                  :controlled="slotProps.controlled"
                ></error-notification>
                <div
                  class="content-header content-header--settings clearfix"
                  id="content-header"
                >
                  <h1 class="page-title">
                    {{ slotProps.title }}
                  </h1>
                </div>
              </div>
            </div>
          </template>
        </component>
      </router-view>
    </div>
    <cobi-footer
      :show-footnote="true"
      :is-bosch-id-enabled="boschEnabled"
    ></cobi-footer>
  </div>
</template>

<script>
  import emitter from '../utils/emitter';
  import ErrorNotification from './common/components/ErrorNotification';
  import SuccessNotificationVue from './common/components/SuccessNotification';

  export default {
    name: 'app',
    data() {
      return {
        focused: false,
      };
    },
    components: {
      'error-notification': ErrorNotification,
      'success-notification': SuccessNotificationVue,
    },
    methods: {
      displaySuccessMessage(text) {
        const successAlertText = document.getElementById('success-alert-text');
        if (!text) {
          successAlertText.textContent = this.$i18n.t('alerts.success');
        } else {
          successAlertText.textContent = text;
        }

        const alertSuccess = document.getElementsByClassName('alert--success')[0];
        alertSuccess.classList.add('show');
        alertSuccess.setAttribute('aria-hidden', 'false');

        setTimeout(() => {
          alertSuccess.classList.remove('show');
          alertSuccess.setAttribute('aria-hidden', 'true');
        }, 3000);
      },
      displayErrorMessage(text) {
        const errorAlertText = document.getElementById('error-alert-text');
        if (!text) {
          errorAlertText.textContent = this.$i18n.t('alerts.error');
        } else {
          errorAlertText.textContent = text;
        }

        const alertError = document.getElementsByClassName('alert--error')[0];
        alertError.classList.add('show');
        alertError.setAttribute('aria-hidden', 'false');

        setTimeout(() => {
          alertError.classList.remove('show');
          alertError.setAttribute('aria-hidden', 'true');
        }, 3000);
      },
    },
    mounted() {
      emitter.on('display-success-message', this.displaySuccessMessage);
      emitter.on('display-error-message', this.displayErrorMessage);
    },
  };
</script>

<style>
@import './common/css/fonts.css';
@import './common/css/general.css';
@import './common/css/button.css';
@import './common/css/global.css';

.terms-container {
  width: 90%;
  margin: 40px auto;
  font-size: 18px;
  font-family: Helvetica, Arial, sans-serif;
}
.UniSansBook-loaded .terms-container {
  font-family: 'UniSansBook', sans-serif;
}
.terms-container .field {
  margin: 30px 0 40px;
}
.terms-container a {
  color: #00c8e6;
}
.terms-container p {
  text-align: left;
}

@media all and (min-width: 640px) {
  .terms-container {
    width: 75%;
    margin: 100px auto;
  }
  .terms-container .privacypolicy__content h1 {
    font-size: 36px;
  }
}
@media all and (min-width: 1024px) {
  .terms-container {
    width: 60%;
  }
}

.terms-table table {
  border-collapse: collapse;
}

.terms-table td,
.terms-table th {
  border: 1px solid #ababab;
  padding: 1rem;
}

.terms-table th {
  background: #e6e6e6;
}

@media screen and (max-width: 550px) {
  .terms-table table tr {
    display: flex;
    flex-direction: column;
  }
}

.pdf-container {
  width: 100%;
  margin: 0 0 50px 0;
}

.skip-links {
  white-space: nowrap;
  margin: 1em auto;
  top: 10%;
  position: fixed;
  left: 50%;
  margin-left: -72px;
  opacity: 0;
}

.skip-links-active {
  opacity: 1;
  background-color: red;
  color: white;
  padding: 0.5em;
  border: 1px solid black;
  z-index: 1000;
}
</style>
