<template>
  <div>
    <slot
      :title="$t('deleteaccount.page-title')"
      :withError="true"
      :withSuccess="true"
    ></slot>
    <div class="content-wrapper">
      <div class="del-acc__container clearfix">
        <div class="del-acc__content">
          <form id="js-del-confirmation-form">
            <fieldset>
              <legend>
                <h1>{{ $t('deleteaccount.header') }}</h1>
                <p>{{ $t('deleteaccount.explanation') }}</p>
              </legend>
              <label
                v-if="!user.isBosch"
                class="field field--floating clearfix"
              >
                <span class="field__label" id="js-del-acc-label">
                  {{ $t('deleteaccount.input-placeholder') }}
                </span>
                <!-- using readonly and removing it on focus so that browser doesn't
                autofill the password because if password is autofilled, floating label
                overlaps the password, and there is no way to detect an autofill event
                in JS to prevent that from happening -->
                <input
                  class="field__input"
                  type="password"
                  id="del-acc-password"
                  required
                  autocomplete="false"
                  readonly
                  v-model="password"
                  @keydown.enter.prevent
                />
                <span class="field__error"></span>
              </label>
              <button
                type="submit"
                id="del-acc-button"
                class="button button--ghost--tarmac delete-account-button spinning-button"
                @click.prevent="buttonClick"
              >
                {{ $t('deleteaccount.button') }}
              </button>
            </fieldset>
          </form>
        </div>
        <div class="del-acc__animated-icon">
          <img
            src="../common/assets/images/svg/blue_bike.svg"
            alt="Blue Bike"
            id="js-blue-bike"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import emitter from '../../utils/emitter';
  import auth from '../common/auth';

  export default {
    name: 'delete-account',
    data() {
      return {
        user: auth.user,
        password: '',
      };
    },
    methods: {
      floatLabels() {
        const input = document.querySelector('.field--floating input');

        input.addEventListener('focus', () => {
          const inputParent = input.parentNode;
          const fieldLabel = inputParent.querySelector('.field__label');
          fieldLabel.classList.add('float');
          fieldLabel.classList.add('focused');
        });

        const endFocusEventHandler = () => {
          const inputValue = input.value;
          const inputParent = input.parentNode;
          const fieldLabel = inputParent.querySelector('.field__label');

          if (!inputValue.length) {
            fieldLabel.classList.remove('float');
            fieldLabel.classList.remove('focused');
            fieldLabel.classList.remove('blurred');
            return;
          }

          fieldLabel.classList.add('float');
          fieldLabel.classList.add('focused');
          fieldLabel.classList.add('blurred');
        };

        input.addEventListener('focusout', endFocusEventHandler);
        input.addEventListener('change', endFocusEventHandler);
        input.addEventListener('blur', endFocusEventHandler);

        const inputParent = input.parentNode;
        const fieldLabel = inputParent.querySelector('.field__label');
        fieldLabel.classList.add('float');

        const inputValue = input.value.trim();

        if (inputValue.length !== 0) {
          fieldLabel.classList.add('float');
          fieldLabel.classList.add('focused');
          fieldLabel.classList.add('blurred');

          return;
        }

        fieldLabel.classList.remove('float');
      },

      async deleteUser() {
        try {
          const postData = this.user.isBosch
            ? {}
            : { password: this.password };

          const res = await auth.doAuthenticatedPost('account/destroy', postData);

          return res.data;
        } catch (err) {
          // eslint-disable-next-line eqeqeq
          if (err.response.data.errors && err.response.data.errors[0].code == 3000022) {
            console.log('wrong password');
          }

          return err.response.data;
        }
      },

      async buttonClick() {
        try {
          if (!this.user.isBosch && this.password === '') return;

          await this.deleteUser();
          auth.clearCachedCheckAuthPromise();
          try {
            await auth.getCachedCheckAuthPromise();
            this.$router.push('/info?successful_deletion');
          } catch (err) {
            this.$router.push('/info?successful_deletion');
          }
        } catch (err) {
          // eslint-disable-next-line eqeqeq
          if (err.errors[0].code == 3000022) {
            emitter.emit('display-error-message', this.$i18n.t('deleteaccount.wrong-password'));
          } else {
            emitter.emit('display-error-message');
          }
        }
      },
    },

    mounted() {
      const input = document.querySelector('#del-acc-password');
      if (input) {
        input.onfocus = function () {
          this.removeAttribute('readonly');
        };
        this.floatLabels();
      }
    },
  };
</script>

<style>
  .del-acc__container {
    width: 90%;
    margin: 40px auto;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
  }
  .UniSansBook-loaded .del-acc__container {
    font-family: 'UniSansBook';
  }
  .del-acc__container .field {
    margin: 30px 0 40px;
  }
  .del-acc__container .field__label {
    width: 100%;
    float: none;
  }
  .del-acc__container .field__input {
    width: 100%;
    float: none;
    max-width: 285px;
  }
  @media all and (min-width: 640px) {
    .del-acc__container {
      width: 75%;
      margin: 100px auto;
    }
    .del-acc__container .del-acc__content h1 {
      font-size: 36px;
    }
  }
  @media all and (min-width: 1024px) {
    .del-acc__container {
      width: 50%;
    }
    .del-acc__container .del-acc__content {
      width: 80%;
      float: left;
    }
    .del-acc__container .del-acc__animated-icon {
      width: 20%;
      float: left;
    }
  }
  @media all and (min-width: 1300px) {
    .del-acc__container .del-acc__content {
      width: 60%;
      float: left;
    }
    .del-acc__container .del-acc__animated-icon {
      width: 40%;
      float: left;
    }
  }

  .del-acc__animated-icon img {
    position: relative;
    right: -125px;
    width: 259px;
    height: 143px;
    max-width: 260px;
    float: right;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
    transition: transform .75s ease-in, opacity .5s ease-in, blur .75s ease-in .5s;
    top: 190px;
  }
  .del-acc__animated-icon img.cycle {
    -webkit-transform: translateX(-800px);
    -ms-transform: translateX(-800px);
    transform: translateX(-800px);
    -webkit-filter: blur(3px);
    filter: blur(3px);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
  @media all and (max-width: 1024px) {
    .del-acc__animated-icon img {
      top: -120px;
      right: -100px;
    }
  }
  @media all and (max-width: 750px) {
    .del-acc__animated-icon img {
      display: none;
    }
  }
  .no-csstransforms .del-acc__animated-icon img {
    display: none;
  }

  .del-acc__content h1 p {
    text-align: left !important;
  }

  .csstransforms .field--floating {
  position: relative;
  margin-bottom: 30px;
  padding: 0;
  display: block;
  width: 100%;
  font-family: inherit;
  padding: 10px 0;
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
}

.csstransforms .field--floating .field__label,
.csstransforms .field--floating .field__input {
  display: block;
}

.csstransforms .field--floating .field__input {
  background: #f3f3f3;
  border: 0;
  padding: 5px 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  height: 38px;
  border-bottom: 1px solid #858e94;
}

.csstransforms .field--floating .field__input:focus,
.csstransforms .field--floating .field__input:active {
  outline: none;
  border-color: #00c8e6;
}

.csstransforms .field--floating .field__input[type="password"] {
  padding: 5px 0;
}

.csstransforms .field--floating .field__label {
  position: absolute;
  top: 0;
  transition: all 0.3s ease-out;
  color: #858e94;
  font-size: 18px;
  padding: 18px 0;
}

.csstransforms .field--floating .field__hint,
.csstransforms .field--floating .field__error {
  display: none;
  margin-top: 50px;
}

.csstransforms .field--floating .field__label.float {
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 13px;
}

.csstransforms .field--floating .field__label.blurred {
  color: #8f979d;
}

.csstransforms .field--floating .field__label.focused {
  color: #2d2d37;
}

fieldset {
  border: 0;
}
</style>
