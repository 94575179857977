<template>
  <div>
    <slot
      :title="$t('devsignup.page-title')"
      :withError="true"
      :withSuccess="true"
    ></slot>
    <div class="content-wrapper content-wrapper-signup">
      <div class="devsignup__container clearfix">
        <div>
          <p><strong>{{ $t('devdiscontinued.headline') }}</strong></p>
          <p v-html="$t('devdiscontinued.text1')"></p>
        </div>
        <div class="devsignup__content">
          <form
            aria-live="polite"
            class="devsignup__form settings__form--notifications"
            id="dev-signup-form"
          >
            <fieldset>
              <legend>
                <h1>{{ $t('devsignup.headline') }}</h1>
                <p v-html="$t('devsignup.text1')"></p>
              </legend>
              <div class="pdf-container">
                <pdf-viewer
                  :url="$t('pdf-viewer.dev-terms-and-conditions')"
                  :pdf-title="$t('devsignup.page-title')"
                  height="35vh"
                ></pdf-viewer>
              </div>
              <div class="checkbox-option">
                <input
                  type="checkbox"
                  name="notifications"
                  id="product-updates"
                  v-model="tosAgreed"
                />
                <label for="product-updates">{{
                  $t('devsignup.checkbox1')
                }}</label>
              </div>
              <div class="settings__form__footer clearfix">
                <button
                  :disabled="!tosAgreed"
                  id="devsignup-button"
                  type="button"
                  class="button settings__form__save-button spinning-button button--filled--blue"
                  @click="submit()"
                >
                  <span class="spinner"></span>
                  <span class="button-label">{{ $t('buttons.accept') }}</span>
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import emitter from '../../utils/emitter';
  import auth from '../common/auth';
  import PDFViewer from '../components/PDFViewer.vue';

  export default {
    components: {
      'pdf-viewer': PDFViewer,
    },
    name: 'dev-signup',
    data() {
      return {
        user: auth.user,
        tosAgreed: false,
        emailAgreed: false,
      };
    },
    methods: {
      submit() {
        const data = {
          url: this.$t('pdf-viewer.dev-terms-and-conditions'),
        };

        auth
          .doAuthenticatedPost('account/developer', data)
          .then((res) => {
            if (res.data) {
              auth.clearCachedCheckAuthPromise();
              return auth.getCachedCheckAuthPromise();
            }

            throw new Error('Something went wrong');
          })
          .then(() => {
            emitter.emit('display-success-message');
            this.$router.push({ path: '/developer' });
          })
          .catch(() => {
            emitter.emit('display-error-message');
          });
      },
    },
  };
</script>

<style>
.devsignup__container {
  width: 100%;
  margin: 40px auto;
  font-size: 18px;
  font-family: Helvetica, Arial, sans-serif;
}
.UniSansBook-loaded .devsignup__container {
  font-family: 'UniSansBook';
}
.devsignup__container .field {
  margin: 30px 0 40px;
}
.devsignup__container a {
  color: #00c8e6;
}

@media all and (min-width: 640px) {
  .devsignup__container {
    width: 75%;
    margin: 100px auto;
  }
  .devsignup__container .devsignup__content h1 {
    font-size: 36px;
  }
}
@media all and (min-width: 1024px) {
  .devsignup__container {
    width: 60%;
  }
  .devsignup__container .devsignup__content {
    width: 100%;
  }
}

.devsignup__form {
  border: 0;
  margin-top: 50px;
  margin-bottom: 100px;
}

.devsignup__form legend {
  display: block;
  width: 100%;
  overflow: hidden;
  clear: both;
}

@media (max-width: 480px) {
  .content-wrapper-signup {
    padding: 0px 20px 0px 20px;
  }
}

fieldset {
  border: 0;
}
</style>
