<template>
  <div>
    <!-- TODO add header title content COFT-2744 -->
    <slot title=""></slot>
    <div class="content-wrapper">
      <div class="infoerror__container clearfix">
        <div class="infoerror__content">
          <h1 id="info_heading"></h1>
          <p id="info_text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import auth from '../common/auth';

  export default {
    name: 'info-error',
    data() {
      return {
        user: auth.user,
      };
    },
    mounted() {
      const infoPageTitle = document.getElementById('info_page-title');
      const infoHeading = document.getElementById('info_heading');
      const infoText = document.getElementById('info_text');

      if (Object.keys(this.$route.query).length === 1) {
        const message = Object.keys(this.$route.query)[0];
        infoText.innerText = this.$parent.$i18n.t(`infoerror.${message}.text`);
        infoHeading.innerText = this.$parent.$i18n.t(`infoerror.${message}.heading`);
        infoPageTitle.innerText = this.$parent.$i18n.t(`infoerror.${message}.page-title`);
      }
    },
  };
</script>

<style>
  .infoerror__container {
    width: 90%;
    margin: 40px auto;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
  }
  .UniSansBook-loaded .infoerror__container {
    font-family: 'UniSansBook';
  }
  .infoerror__container .field {
    margin: 30px 0 40px;
  }
  .infoerror__container a{
    color: #00c8e6;
  }

  @media all and (min-width: 640px) {
    .infoerror__container {
      width: 75%;
      margin: 100px auto;
      text-align: center;
    }
    .infoerror__container .infoerror__content h1 {
      font-size: 36px;
    }
  }
</style>
