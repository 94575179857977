import Settings from 'Pages/Settings.vue';
import Account from 'Pages/Account.vue';
import DeleteAccount from 'Pages/DeleteAccount.vue';
import DevLanding from 'Pages/DevLanding.vue';
import DevSignup from 'Pages/DevSignup.vue';
import TermsOfService from 'Pages/TermsOfService.vue';
import InfoError from 'Pages/InfoError.vue';
import BuildInformation from 'Pages/BuildInformation.vue';
import HubRelease from 'Pages/HubRelease.vue';
import UserInfo from 'Pages/UserInfo.vue';
import {
  GuardsCheck,
  isLoggedIn,
  hasRole,
  notHasRole,
} from 'Common/router/guards';
import { FEATURE_ENABLE_BOSCH_ID } from '../../config';
import { cobiLegalRoutes, boschIdLegalRoutes } from './legalRoutes';

const legalRoutes = FEATURE_ENABLE_BOSCH_ID
  ? boschIdLegalRoutes
  : cobiLegalRoutes;
const routes = [
  ...legalRoutes,
  {
    path: '/tos',
    name: 'developerterms',
    component: TermsOfService,
    alias: '/developer-terms/v2',
  },
  {
    path: '/developer-terms/v1',
    name: 'developertermsv1',
    component: TermsOfService,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/account/delete',
    name: 'deleteaccount',
    component: DeleteAccount,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/developer',
    name: 'developer_landing',
    component: DevLanding,
    beforeEnter: GuardsCheck([
      isLoggedIn,
      hasRole('developer', 'developer_signup'),
    ]),
  },
  {
    path: '/developer/signup',
    name: 'developer_signup',
    component: DevSignup,
    beforeEnter: GuardsCheck([isLoggedIn, notHasRole('developer')]),
  },
  {
    path: '/loggedin',
    redirect: { name: 'settings' },
  },
  {
    path: '/info',
    name: 'info',
    component: InfoError,
  },
  {
    path: '/build-information',
    name: 'build_information',
    component: BuildInformation,
  },
  {
    path: '/hub-release',
    name: 'hub_release',
    component: HubRelease,
  },
  {
    path: '/user-info',
    name: 'user-info',
    component: UserInfo,
  },
];

export default routes;
