<template>
  <!-- Bosch ID FAQ start  -->
  <li class="accordion-item" :class="{ 'is-closed': !accordionList.includes(id) }">
    <a href class="accordion-title" @click.prevent="toggleAccordion(id)" v-html="faqItem['question']"
      role="accordion-title"></a>
    <div class="accordion-content" ref="content" v-html="faqItem['answer']" role="accordion-content">
    </div>
  </li>
  <!-- Bosch ID FAQ end  -->
</template>

<script>
  export default {
    name: 'bosch-id-faq',
    props: [
      'faqItem', 'id', 'toggleAccordion', 'accordionList',
    ],
    data() {
      return {};
    },
  };
</script>

<style scoped>
.accordion-item {
  margin-bottom: 2px;
  background: #F3F3F3;
  width: 100%;
  float: left;
}

.accordion-item:first-child> :first-child,
.accordion-item:last-child> :last-child {
  border-radius: 0;
}

.accordion-title,
.accordion-content {
  background: white;
}

.accordion-title {
  position: relative;
  display: block;
  font-size: 1.1rem;
  padding: 1rem 4rem 1rem 1rem;
  font-weight: 100;
  overflow: hidden;
}

.accordion-title .toggle_button {
  position: relative;
  content: url("../common/assets/images/svg/icon-accordion-toggler.svg");
  width: 30px;
  height: 30px;
}

.accordion-title::after {
  position: absolute;
  float: right;
  top: calc(1em - 3px);
  right: 1em;
  content: url("../common/assets/images/svg/icon-accordion-toggler.svg");
  width: 30px;
  height: 30px;
  transition: transform 0.2s ease-in-out;
}

.accordion-title span:hover,
.accordion-title span:focus {
  color: #00c8e6;
  transition: all 0.2s ease-out;
}

.is-closed .accordion-title::after {
  transform: rotate(180deg);
}

.accordion-content {
  color: #2d2d37;
  max-height: 10000px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  font-size: 1.05rem;
  /*  fake border */
  box-sizing: border-box;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.accordion-content:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2px;
  background: #f3f3f3;
}

.is-closed .accordion-content {
  max-height: 0;
}

.accordion-content :deep(p) {
  font-weight: 300;
  color: #747477;
  padding: 0 16px;
  text-align: left;
}

.button,
.product-faq .more-link a,
a,
button {
  transition: all 0.2s ease-out;
}
</style>
