<template>
  <div class="hubs">
    <div class="hubs-header" role="hubs-header" aria-hidden="true">
      <h2 class="form__title hubs-title" role="hubs-title">
        {{ $t('hub_table.table_title') }}
      </h2>
      <a class="icon-link" :href="$t('hub_table.faq_link_url')">
        <img src="../common/assets/images/svg/info.svg" :alt="$t('hub_table.info_alt')"
          :title="$t('hub_table.info_title')" />
        <h3 class="">{{ $t('hub_table.faq_link') }}</h3>
      </a>
    </div>
    <div class="hubs-container" role="table" :aria-label="$t('hub_table.table_title')"
      aria-describedby="hubs-table-caption">
      <div id="hubs-table-caption">
        {{ $t('hub_table.caption') }}
      </div>
      <div class="hubs-row hubs-header" v-if="hubs.length > 0" role="row">
        <div class="hubs-field hubs-serial-number" role="columnheader">
          {{ $t('hub_table.table_serial') }}
        </div>
        <div class="hubs-field hubs-activation-time" role="columnheader">
          {{ $t('hub_table.table_added_on') }}
        </div>
        <div class="hubs-field hubs-type" role="columnheader">
          {{ $t('hub_table.table_device_type') }}
        </div>
        <div class="hubs-field hubs-role" role="columnheader">
          {{ $t('hub_table.table_ownership') }}
        </div>
        <div class="hubs-field hubs-sharing-mode" role="columnheader">
          {{ $t('hub_table.table_share') }}
        </div>
        <div class="hubs-field hubs-actions" role="columnheader"></div>
      </div>
      <div class="hubs-row-container" v-for="hub in hubs" :key="hub.serialNumber" role="rowgroup">
        <div class="hubs-row" role="row">
          <div class="hubs-field hubs-serial-number" role="cell">
            {{ hub.serialNumber }}
          </div>
          <div class="hubs-field hubs-activation-time" role="cell">
            {{ hub.addedOn }}
          </div>
          <div class="hubs-field hubs-type" role="cell">
            {{ $t('hub_table.device_types.' + hub.type) }}
          </div>
          <div class="hubs-field hubs-role" role="cell">
            <img v-if="hub.role === 'owner'" :class="`hubs-icon ${hub._deleteConfirmation ? 'inverted-icon' : ''
            }`" :alt="$t('hub_table.owner_alt')" :title="$t('hub_table.owner_title')"
              src="../common/assets/images/svg/owner.svg" />
            <img v-if="hub.role === 'guest'" :class="`hubs-icon ${hub._deleteConfirmation ? 'inverted-icon' : ''
            }`" :alt="$t('hub_table.guest_alt')" :title="$t('hub_table.guest_title')"
              src="../common/assets/images/svg/guest.svg" />
          </div>
          <div class="hubs-field hubs-sharing-mode" role="cell">
            <div class="controls" v-if="!hub._deleteConfirmation && hub.role === 'owner'">
              <label id="slowmo">
                <input type="checkbox" v-model="hub.sharing" @click="changeSharing($event, hub)"
                  :disabled="hub._sharingChange" />
                <div class="control" v-if="!hub._sharingChange"></div>
              </label>
              <div v-if="hub._sharingChange">
                <infinity-loader width="30"></infinity-loader>
              </div>
            </div>
            <transition name="sharingtoggle">
              <div>
                <img v-if="
                  hub.sharing &&
                  (hub._deleteConfirmation || hub.role === 'guest')
                " :class="`hubs-icon ${hub._deleteConfirmation ? 'inverted-icon' : ''
}`" :alt="$t('hub_table.sharing_mode_on_alt')" :title="$t('hub_table.sharing_mode_on_title')"
                  src="../common/assets/images/svg/sharing-mode-on.svg" />
                <img v-if="
                  !hub.sharing &&
                  (hub._deleteConfirmation || hub.role === 'guest')
                " :class="`hubs-icon ${hub._deleteConfirmation ? 'inverted-icon' : ''
}`" :alt="$t('hub_table.sharing_mode_off_alt')" :title="$t('hub_table.sharing_mode_off_title')"
                  src="../common/assets/images/svg/sharing-mode-off.svg" />
              </div>
            </transition>
          </div>
          <div class="hubs-field hubs-actions" role="cell">
            <button class="delete-hub-button" aria-expanded="false" aria-haspopup="true"
              :aria-controls="`del-hub-confirmation-prompt-${hub.serialNumber}`" @click="deleteHub($event, hub)"
              @mouseover="hub._buttonHover = true" @mouseleave="hub._buttonHover = false"
              :aria-label="$t('hub_table.delete_hub')" :class="{ hover: hub._buttonHover }">
              <svg class="discard-icon" width="42" height="42">
                <desc>{{ $t('alt_text.discard') }}</desc>
                <use href="#discard-icon"></use>
                <image src="../assets/images/fallback/discard-icon.png" :alt="$t('alt_text.discard')"></image>
              </svg>
            </button>
          </div>
        </div>
        <transition name="fade">
          <div class="del-hub-confirmation-prompt" v-if="hub._deleteConfirmation"
            :id="`del-hub-confirmation-prompt-${hub.serialNumber}`" role="alert" tabindex="-1">
            <div>
              <p>
                {{
                    hub.role === 'owner'
                      ? $t('hub_table.delete_hub_text1')
                      : $t('hub_table.request_release_confirmation_text')
                }}
              </p>
              <div>
                <button class="button button--confirmation button--white"
                  :id="`cancel-del-hub-button-${hub.serialNumber}`" @click="cancelDeleteHub($event, hub)">
                  {{ $t('hub_table.delete_hub_cancel_button') }}
                </button>
                <button class="button button--confirmation button--coral"
                  :id="`confirm-del-hub-button-${hub.serialNumber}`" @click="confirmDeleteHub($event, hub)">
                  {{
                      hub.role === 'owner'
                        ? $t('hub_table.delete_hub_confirm_button')
                        : $t('hub_table.request_release_confirm_button')
                  }}
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="hubs__notification" v-if="hubs.length === 0 && !loading" role="row">
        <p role="cell">{{ $t('hub_table.no_hubs_notification') }}</p>
      </div>
      <div class="hubs__notification" v-if="loading" role="row">
        <div role="cell">
          <infinity-loader width="60"></infinity-loader>
        </div>
        <p>{{ $t('hub_table.loading_hubs') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import InfinityLoader from 'Common/components/InfinityLoader.vue';
  import auth from '../common/auth';
  import formatDate from '../common/utils/formatDate';
  import emitter from '../../utils/emitter';

  export default {
    name: 'hubs-table',
    components: {
      'infinity-loader': InfinityLoader,
    },
    data() {
      return {
        hubs: [],
        loading: true,
      };
    },
    methods: {
      async getHubsFromCloud() {
        try {
          const devices = await auth.doAuthenticatedGet('hubs');
          this.loading = false;

          if (devices.data && devices.data.length > 0) {
            this.hubs = devices.data.map((hub) => {
              const d = new Date(hub.activationTime);

              return {
                serialNumber: hub.serial,
                partNumber: hub.partNumber,
                addedOn: formatDate(d),
                sharing: hub.sharingMode === 'public',
                type: hub.type,
                role: hub.role,
                _deleteConfirmation: false,
                _buttonHover: false,
                _sharingChange: false,
              };
            });
          }
        } catch (err) {
          console.log(err);
          emitter.emit('display-error-message');
        }
      },
      deleteHub(event, hub) {
        const deleteButton = event.target;

        // shows confirmation prompt
        // eslint-disable-next-line no-param-reassign
        hub._deleteConfirmation = true;

        deleteButton.setAttribute('aria-expanded', 'true');

        const row = deleteButton.closest('div.hubs-row');

        setTimeout(() => {
          row.style.backgroundColor = '#2d2d37';
          row.style.color = '#fff';
        }, 150);

        // using visiblity instead of display none because we want the screen reader to be able to
        // read the aria-expanded = true information
        deleteButton.style.visibility = 'hidden';
      },
      cancelDeleteHub(event, hub) {
        const cancelButton = event.target;

        const rowContainer = cancelButton.closest('div.hubs-row-container');
        const row = rowContainer.querySelector('div.hubs-row');
        const rowDeleteButton = row.querySelector('.delete-hub-button');
        const confirmation = rowContainer.querySelector(
          '.del-hub-confirmation-prompt'
        );

        confirmation.classList.add('hide');
        row.style.backgroundColor = '';
        row.style.color = '';
        rowDeleteButton.style.visibility = 'visible';
        rowDeleteButton.setAttribute('aria-expanded', 'false');

        // For some reason the visibility: visible is not propagating automatically to the children
        const svgContainer = rowDeleteButton.querySelector('svg');
        const useContainer = svgContainer.querySelector('use');
        svgContainer.style.visibility = 'visible';
        useContainer.style.visibility = 'visible';

        // eslint-disable-next-line no-param-reassign
        hub._deleteConfirmation = false;
      },
      async confirmDeleteHub(event, hub) {
        try {
          const confirmDeleteButton = event.target;
          confirmDeleteButton.classList.add('loading');

          const rowContainer = confirmDeleteButton.closest(
            'div.hubs-row-container'
          );
          const row = rowContainer.querySelector('div.hubs-row');

          if (hub.role === 'owner') {
            const res = await auth.doAuthenticatedDelete(
              `hubs/${hub.serialNumber}+${hub.partNumber}/authorization`
            );
            if (!res.data) {
              throw new Error('Something went wrong with release hub');
            }

            confirmDeleteButton.classList.remove('loading');
            emitter.emit(
              'display-success-message',
              this.$i18n.t('hub_table.hub_release_success_notification')
            );

            row.classList.add('hide');
            if (this.hubs) {
              this.hubs = this.hubs.filter(
                (x) => x.serialNumber !== hub.serialNumber
              );
            }
          } else {
            await auth.doAuthenticatedGet(
              `hubs/${hub.serialNumber}+${hub.partNumber}/release`
            );

            confirmDeleteButton.classList.remove('loading');
            emitter.emit(
              'display-success-message',
              this.$i18n.t('hub_table.request_release_success_notification')
            );

            this.cancelDeleteHub(event, hub);
          }
        } catch (err) {
          console.log(err);
          emitter.emit('display-error-message');
        }
      },
      changeSharing(event, hub) {
        if (Number.isNaN(Number(hub.serialNumber))) {
          emitter.emit('display-error-message');
          console.log(
            'Changing sharingmode failed, serial number is not a number',
            hub
          );
          return;
        }

        const loaderTimeout = setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          hub._sharingChange = true;
        }, 300);
        this.$nextTick(async () => {
          try {
            await auth.doAuthenticatedPut(
              `hubs/${hub.serialNumber}+${hub.partNumber}/settings`,
              { sharingMode: !hub.sharing ? 'public' : 'off' }
            );

            clearTimeout(loaderTimeout);
            // eslint-disable-next-line no-param-reassign
            hub._sharingChange = false;
          } catch (err) {
            clearTimeout(loaderTimeout);
            // eslint-disable-next-line no-param-reassign
            hub._sharingChange = false;
            emitter.emit('display-error-message');
          }
        });
      },
    },
    async mounted() {
      await this.getHubsFromCloud();
    },
  };
</script>

<style>
.controls {
  top: 50%;
  left: 50%;
  margin-top: auto;
  white-space: nowrap;
}

.controls label {
  cursor: pointer;
  display: inline;
}

.controls label input {
  display: none;
}

.controls label input:checked+.control {
  background-color: #2d2d37;
  margin-left: 0px;
}

.controls label input:checked+.control:after {
  background-color: #00c8e6;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.3);
  margin-left: 16px;
}

.controls label div {
  display: inline-block;
}

.controls label .control {
  width: 36px;
  height: 16px;
  border-radius: 8px;
  background-color: #bbb;
  margin-left: 5px;
}

.controls label .control:after {
  display: block;
  content: '';
  background-color: #f1f1f1;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-top: -4px;
  border-radius: 12px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
  -webkit-transition: 225ms;
  transition: 225ms;
}

.hubs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.hubs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 63%;
}

.icon-link {
  display: flex;
  align-items: center;
}

.icon-link img {
  margin-right: 10px;
}

.hubs-row {
  transition: all 0.4s ease-in-out;
}

.hubs-title {
  margin-bottom: 0;
}

.hubs-row.hide {
  height: 0;
}

.fade-enter-active,
.fade-leave-active {
  max-height: 310px;
  overflow-y: hidden;
  -webkit-transition: max-height 0.4s linear;
  -moz-transition: max-height 0.4s linear;
  -o-transition: max-height 0.4s linear;
  transition: max-height 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  max-height: 0px;
}

.sharingtoggle-enter-active {
  opacity: 1;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

.sharingtoggle-enter,
.sharingtoggle-leave-to {
  opacity: 0;
}

.hubs-icon {
  width: 30px;
  height: 30px;
}

.inverted-icon {
  filter: invert(1);
}

.hubs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  width: 63%;
  overflow: auto;
}

.hubs-row-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.hubs-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: background-color 0.2s linear;
  font-weight: 300;
}

.hubs-row-container:nth-of-type(even) {
  background-color: #fff;
}

.hubs-header {
  font-weight: bold;
  background-color: rgb(243, 243, 243);
}

.hubs-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 5px;
}

.hubs-serial-number {
  flex: 3;
}

.hubs-type {
  flex: 4;
}

.hubs-role {
  flex: 2;
}

.hubs-activation-time {
  flex: 2;
}

.hubs-sharing-mode {
  flex: 2;
}

.hubs-actions {
  flex: 2;
}

.del-hub-confirmation-prompt {
  overflow: hidden;
  background-color: #2d2d37;
  width: 100% !important;
  display: block;
  color: #fff;
  padding: 10px 20px 20px !important;
  transition: all 0.2s ease-in-out;
}

.del-hub-confirmation-prompt.hide {
  height: 0;
}

.del-hub-confirmation-prompt .button--coral {
  float: right;
}

.hubs-field .delete-hub-button {
  float: right;
  margin-right: 5px;
  background: none;
  border: none;
  max-width: 100%;
}

.delete-hub-button {
  position: relative;
  /* using .hover instead of :hover to avoid the "sticky hover state" bug/effect in Webkit & Blink */
}

.delete-hub-button use {
  fill: #2d2d37;
  color: transparent;
  transition: all 0.2s linear;
}

.delete-hub-button.hover use,
.delete-hub-button:active use,
.delete-hub-button:focus use {
  fill: #fff;
  color: #2d2d37;
}

@media all and (max-width: 768px) {

  .hubs-type,
  .hubs-role,
  .hubs-activation-time {
    display: none;
  }

  .hubs-header {
    width: 100%;
  }

  .hubs-container {
    width: 100%;
  }
}

#hubs-table-caption {
  visibility: hidden;
}
</style>
