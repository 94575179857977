module.exports = {
  PORT: process.env.PORT,
  HOST: process.env.MY_COBI_HOST,
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.API_URL,
  WEB_URL: process.env.WEB_URL,
  CIAM_PROFILE_URI: process.env.CIAM_PROFILE_URI,
  FEATURE_ENABLE_BOSCH_ID: JSON.parse(process.env.FEATURE_ENABLE_BOSCH_ID || false),
  LANGUAGES: (process.env.LANGUAGES || 'en').split(','), // we use split because env keys don't support arrays
  OBC_KC_LOGOUT_URI: process.env.OBC_KC_LOGOUT_URI,
  OBC_KC_REDIRECT_URI: process.env.OBC_KC_REDIRECT_URI,
  PHRASE_API_TOKEN: process.env.PHRASE_API_TOKEN,
}
