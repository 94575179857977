<template>
  <div>
    <slot
      :title="$t('privacypolicy.page-title')"
    ></slot>
    <div class="content-wrapper">
      <div class="terms-container clearfix pdf-container">
        <pdf-viewer
          :url="ppUrl"
          :pdf-title="$t('privacypolicy.page-title')"
        ></pdf-viewer>
      </div>
    </div>
  </div>
</template>
<script>
  import PDFViewer from '../components/PDFViewer.vue';
  import { useRoute } from 'vue-router';
 
  export default {
    data() {
      return {
        ppUrl: this.$i18n.t('pdf-viewer.privacy-policy'),
      };
    },

    mounted() {
      const route = useRoute();
      const locale = route.query.locale;
      const ppBaseUrlArr = this.ppUrl.split('_');
      const ppBaseUrl = ppBaseUrlArr[0];
      if(locale === 'en-us' || locale === 'en-ca') {
        this.ppUrl = ppBaseUrl + '_Policy_US_CA_EN.pdf';
      }
      if(locale === 'fr-ca') {
        this.ppUrl = ppBaseUrl + '_Policy_CA_FR.pdf';
      }
    },
    
    components: {
      'pdf-viewer': PDFViewer,
    },
  };
</script>
