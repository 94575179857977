<template>
  <div>
    <slot
      :title="$t('devterms.page-title')"
    ></slot>
    <div class="content-wrapper">
      <div class="tos__container clearfix pdf-container">
        <pdf-viewer
          :url="$t('pdf-viewer.terms-and-conditions')"
          :pdf-title="$t('devterms.page-title')"
        ></pdf-viewer>
      </div>
    </div>
  </div>
</template>

<script>
  import PDFViewer from '../components/PDFViewer.vue';

  export default {
    name: 'terms-of-service',
    components: {
      'pdf-viewer': PDFViewer,
    },
  };
</script>

<style>
  .tos__container {
    width: 90%;
    margin: 40px auto;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
  }
  .UniSansBook-loaded .tos__container {
    font-family: 'UniSansBook';
  }
  .tos__container .field {
    margin: 30px 0 40px;
  }
  .tos__container a {
    color: #00c8e6;
  }
  .tos__container p {
    text-align: left;
  }

  @media all and (min-width: 640px) {
    .tos__container {
      width: 75%;
      margin: 100px auto;
    }
    .tos__container .tos__content h1 {
      font-size: 36px;
    }
  }
  @media all and (min-width: 1024px) {
    .tos__container {
      width: 60%;
    }
    .tos__container .tos__content {
      width: 80%;
      float: left;
    }
  }
  @media all and (min-width: 1300px) {
    .tos__container .tos__content {
      width: 60%;
      float: left;
    }
  }
</style>
