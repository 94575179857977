<template>
  <div>
    <slot title="Hub Status"></slot>
    <div class="content-wrapper">
      <div class="release__container clearfix">
        <div class="release__content">
          <p>{{ $t('hubstatus.release_success') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.release__container {
  width: 90%;
  margin: 40px auto;
  font-size: 18px;
  font-family: Helvetica, Arial, sans-serif;
}

.UniSansBook-loaded .release__container {
  font-family: 'UniSansBook';
}

@media all and (min-width: 640px) {
  .release__container {
    width: 75%;
    margin: 100px auto;
    text-align: center;
  }

  .release__container h1 {
    font-size: 36px;
  }
}
</style>
