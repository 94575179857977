<template>
  <div>
    <slot
      :title="$t('servicedescription.page-title')"
    ></slot>
    <div class="content-wrapper">
      <div class="terms-container clearfix pdf-container">
        <pdf-viewer
          :url="url"
          :pdf-title="$t('servicedescription.page-title')"
        ></pdf-viewer>
      </div>
    </div>
  </div>
</template>

<script>
  import PDFViewer from '../components/PDFViewer.vue';
  import auth from '../common/auth';

  export default {
    name: 'service-description',
    data() {
      return {
        url: '',
      };
    },
    components: {
      'pdf-viewer': PDFViewer,
    },
    async mounted() {
      try {
        const result = await auth.doAuthenticatedGet('service-description/url');
        this.url = result.url;
      } catch (err) {
        this.url = `https://cdn.cobi.bike/static/legal-agreements/service-description/general/Service_Description_${this.$i18n.locale.toUpperCase()}.pdf`;
      }
    },
  };
</script>
