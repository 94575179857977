<template>
  <div>
    <slot
      :title="$t('terms.page-title')"
    ></slot>
    <div class="content-wrapper">
      <div class="terms-container clearfix pdf-container">
        <PDFViewer
          :url="$t(`pdf-viewer.terms-and-conditions`)"
          :pdf-title="$t(`terms.beta.${paramVersion}.title`)"
        ></PDFViewer>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { useRoute } from 'vue-router';
  import { onMounted } from 'vue';
  import PDFViewer from '../components/PDFViewer.vue';

  const route = useRoute();
  let paramVersion = route?.query.version;
  const versions = ['v1', 'v2', 'latest'];

  onMounted(() => {
    if (versions.includes(paramVersion)) {
      paramVersion = `${route.name}.${paramVersion}`;
      return { paramVersion };
    }

    window.location.href = '/terms?version=latest';
    return {};
  });
</script>

<style>
  .version_list {
    padding-left: 30px;
    margin-top: 10px;
    border-top: 1px solid #d7d7d7;
  }
</style>
