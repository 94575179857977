<template>
    <div class="container">
        <iframe
            :src="src"
            :width="width"
            :height="pdfHeight"
            :style="pdfStyle"
            :title="pdfTitle"
        />
    </div>
</template>
<script>
  import config from '../../config';

  export default {
    name: 'pdf-viewer',
    props: {
      url: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
      },
      pdfStyle: {
        type: String,
        default: 'border: none;',
      },
      pdfTitle: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        windowHeight: window.innerHeight,
      };
    },
    computed: {
      src() {
        return `${config.HOST}/lib/pdf/web/viewer.html?file=${encodeURIComponent(this.url)}`;
      },
      pdfHeight() {
        // incase of height prop is provided, we have to make sure the value is converted to px
        // NOTE: iframe accepts only px and % format, vh is taken as px
        if (this.height) {
          if (this.height.includes('vh')) {
            const height = this.extractHeight('vh');
            return (this.windowHeight * height) / 100;
          }
          if (this.height.includes('px') || this.height.includes('%')) {
            return this.height;
          }
        }

        return this.windowHeight;
      },
    },
    methods: {
      handleResize() {
        this.windowHeight = window.innerHeight;
      },
      extractHeight(unit) {
        const [heightValue] = this.height.split(unit);
        return heightValue;
      },
    },
    async mounted() {
      if (!this.height) {
        window.addEventListener('resize', this.handleResize);
      }
    },
    unmounted() {
      window.removeEventListener('resize', this.handleResize);
    },
  };
</script>
