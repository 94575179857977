<template>
  <div>
    <slot
      :title="$t('settings.page-title')"
      :withSuccess="true"
      :withError="true"
    ></slot>
    <svg style="display: none">
      <symbol viewBox="0 0 42 42" id="discard-icon">
        <title>Delete this hub</title>
        <path
          d="M19.5,12.8H15.4a0.8,0.8,0,0,0-.8.8v0.8H27.4V13.6a0.8,0.8,0,0,0-.8-0.8H22.5V12.4a1.2,1.2,0,0,0-1.3-1.2H20.8a1.2,1.2,0,0,0-1.3,1.3v0.4h0Z"
        />
        <circle
          cx="21"
          cy="21"
          r="20"
          stroke="#2d2d37"
          fill="currentColor"
        ></circle>
        <path
          d="M25.7,17.7l-1.3,9.9a0.9,0.9,0,0,1-.8.7H18.4a0.9,0.9,0,0,1-.8-0.7l-1.3-9.9h9.4m1.7-1.5H14.6l1.6,11.6a2.4,2.4,0,0,0,2.3,2h5.1a2.3,2.3,0,0,0,2.3-2l1.6-11.6h0Z"
        />
        <path
          d="M19.5,12.8H15.4a0.8,0.8,0,0,0-.8.8v0.8H27.4V13.6a0.8,0.8,0,0,0-.8-0.8H22.5V12.4a1.2,1.2,0,0,0-1.3-1.2H20.8a1.2,1.2,0,0,0-1.3,1.3v0.4h0Z"
        />
      </symbol>
    </svg>
    <!-- keeping it here for backwards compat. Please use common/components/InfinityLoader.vue -->
    <div style="display: none">
      <svg width="100" height="auto" id="infinity_loader" viewBox="0 0 100 55">
        <g
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
        >
          <g
            id="Background"
            transform="translate(-4540.000000, -2006.000000)"
            stroke="#00C8E6"
            stroke-width="5"
          >
            <path
              d="M4593.24263,2042.26254 C4595.18535,2049.99195 4602.888,2052.8618 4602.888,2052.8618 C4605.992,2054.3648 4609.476,2055.2068 4613.157,2055.2068 C4626.193,2055.2068 4636.761,2044.6388 4636.761,2031.6038 C4636.761,2018.5678 4626.193,2007.9998 4613.157,2007.9998 C4601.693,2007.9998 4592.138,2016.1728 4590,2027.0098 L4588.761,2036.1968 C4586.623,2047.0338 4577.068,2055.2068 4565.604,2055.2068 C4552.568,2055.2068 4542,2044.6388 4542,2031.6038 C4542,2018.5678 4552.568,2007.9998 4565.604,2007.9998 C4569.284,2007.9998 4572.768,2008.8418 4575.873,2010.3448 C4575.873,2010.3448 4583.07027,2013.85339 4584.99504,2021.67304"
              class="infinity_loader_path"
            ></path>
          </g>
        </g>
      </svg>
    </div>

    <div class="content-wrapper">
      <div class="landing__content">
        <!-- user.isBosch: undefined is a default state, which means we don't know yet -->
        <migration-info-notice
          v-if="this.boschEnabled && user.isBosch === false"
        ></migration-info-notice>
        <h1 v-if="user.authenticated">
          {{ $t('index.headline_authenticated', { username: user.name }) }}
        </h1>
        <p class="lead-settings">{{ $t('index.text1') }}</p>
      </div>
      <div
        class="settings__forms settings__form--profile"
        id="hub-settings-form"
      >
        <hub-table></hub-table>
      </div>
    </div>
  </div>
</template>

<script>
  import auth from '../common/auth';
  import HubTable from '../components/HubTable.vue';
  import MigrationInfoNotice from '../common/components/MigrationInfoNotice.vue';

  export default {
    name: 'hub-settings',
    components: {
      'hub-table': HubTable,
      'migration-info-notice': MigrationInfoNotice,
    },
    data() {
      return {
        user: auth.user,
      };
    },
  };
</script>

<style>
.form__title {
  margin-left: 20px;
}

.landing__content {
  margin: 40px auto;
  width: 60%;
  padding-left: 20px;
}

.settings__form {
  border: 0;
  margin-bottom: 100px;
}
.settings__form legend {
  display: block;
  width: 100%;
  overflow: hidden;
  clear: both;
}

.form__title {
  margin-bottom: 30px;
  float: left;
  line-height: 1.4;
  font-size: 30px;
  display: block;
  overflow: hidden;
  clear: both;
}

.settings__form__footer {
  margin-top: 40px;
}
.settings__form__footer .settings__form__save-button {
  float: right;
}

@media all and (max-width: 480px) {
  .hub__date-added {
    clear: both;
  }

  .settings__form {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .form__title {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  .landing__content {
    margin-top: 40px;
    width: 95%;
    padding-left: 5px;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 3000;
  }
}

#infinity_loader {
  padding-top: 5px;
  stroke-dasharray: 60, 200;
  animation: dash 10s linear infinite;
}

@media all and (min-width: 640px) {
  .landing__content {
    margin-top: 40px;
    width: 95%;
    padding-left: 10px;
  }

  .landing__container .landing__content h1 {
    font-size: 36px;
  }
}

@media all and (min-width: 1024px) {
  .landing__container {
    width: 60%;
  }
  .landing__container .landing__content {
    float: left;
  }

  .landing__content {
    margin-top: 40px;
    width: 60%;
    padding-left: 20px;
  }
}
@media all and (min-width: 1300px) {
  .landing__container .landing__content {
    float: left;
  }
}

.lead-settings {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 300;
  text-align: left;
  float: left;
  padding: 0 !important;
}

.lead-settings strong {
  font-weight: 500;
}

.lead-settings p {
  text-align: left;
}
</style>
