<template>
  <div>
    <slot
      :title="$t(`corporate-information.title`)"
    ></slot>
    <div class="content-wrapper">
      <div class="terms-container clearfix pdf-container">
        <pdf-viewer
          :url="$t('pdf-viewer.corporate-information')"
          :pdf-title="$t('corporate-information.page-title')"
        ></pdf-viewer>
      </div>
    </div>
  </div>
</template>
<script>
  import PDFViewer from '../components/PDFViewer.vue';

  export default {
    components: {
      'pdf-viewer': PDFViewer,
    },
  };
</script>