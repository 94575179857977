<template>
  <div>
    <slot
      :title="$t('myaccount.page-title')"
      :withError="true"
      :withSuccess="true"
    ></slot>
    <div class="content-wrapper">
      <div class="landing__container__account clearfix">
        <!-- content start -->
        <div class="settings__container clearfix">
          <div class="content-wrapper">
            <div class="settings__profile-photo">
              <img
                src="../common/assets/images/svg/profile-photo.svg"
                :alt="$t('alt_text.profile_photo')"
              />
            </div>
            <div
              class="settings__forms settings__form--profile"
              id="account-settings-form"
            >
              <user-data></user-data>
              <user-email></user-email>
              <user-delete-account></user-delete-account>
              <user-manage-bosch-id
                v-if="user.isBosch === true"
              ></user-manage-bosch-id>
            </div>
          </div>
        </div>
        <!-- content end -->
      </div>
    </div>
  </div>
</template>

<script>
  import UserData from 'Components/UserData.vue';
  import UserEmail from 'Components/UserEmail.vue';
  import UserDeleteAccount from 'Components/UserDeleteAccount.vue';
  import UserManageBoschId from 'Components/UserManageBoschID.vue';

  import auth from '../common/auth';

  export default {
    name: 'account',
    data() {
      return {
        user: auth.user,
      };
    },
    components: {
      'user-data': UserData,
      'user-email': UserEmail,
      'user-delete-account': UserDeleteAccount,
      'user-manage-bosch-id': UserManageBoschId,
    },
  };
</script>

<style>
.settings__container {
  width: 100%;
  margin: 0 auto;
}

.settings__profile-photo {
  width: 100%;
  padding: 65px 20px;
  text-align: center;
}

.settings__profile-photo img {
  display: inline-block;
  width: 160px;
  height: 160px;
}

@media all and (max-width: 480px) {
  .settings__profile-photo img {
    width: 100px;
    height: 100px;
  }
}

@media all and (max-width: 480px) {
  .settings__profile-photo {
    padding: 40px 20px;
  }
}

.form__title {
  margin-left: 0px;
}

.settings__forms {
  width: 90%;
}

.landing__container__account {
  margin-top: 0px !important;
}

.landing__container__account {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 50px;
  width: 100%;
}

.landing__container__account a {
  text-decoration: underline;
}

.landing__container__account a.button {
  color: #fa4b69 !important;
  text-decoration: none;
  float: right;
  margin-right: 0px !important;
  min-width: 180px;
}
.landing__container__account a.button:hover,
.landing__container__account a.button:focus {
  color: white !important;
  background-color: #fa4b69 !important;
  border-color: #fa4b69 !important;
}

@media (min-width: 640px) {
  .landing__container__account {
    width: 65%;
    margin: 100px auto;
  }
}

.grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.2;
}
</style>
