<template>
  <div class="migration-info">
    <div class="migration-info--lead">
      <h1>{{ $i18n.t('migration-info.headline')}}</h1>
      <div v-html="$i18n.t('migration-info.text-lead')" />
    </div>
    <div class="migration-info--steps-wrapper">
      <div class="migration-info--steps-content-wrapper">
        <h2>{{ $i18n.t('migration-info.text-steps-headline') }}</h2>
        <div
          class="migration-info--steps-text"
          v-html="$i18n.t('migration-info.text-steps-content')" />
      </div>
    </div>
  </div>
</template>

<style>
  .migration-info {
    background-color: #fff;
    text-align: left;
    font-size: 1.125rem;
    font-weight: 300;
    clear: both;
    box-shadow: inset 0 0 2px #e3e3e3;
    padding: 3rem;
    font-family: UniSansBook, sans-serif;
    margin-bottom: 3rem;
  }

  .migration-info--lead {
    margin-bottom: 3rem;
  }
  .migration-info p {
    text-align: left;
  }

  .migration-info--steps-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .migration-info--steps-content-wrapper {
    max-width: 447px;
    width: 100%;
  }

  .migration-info--steps-wrapper h2 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  .migration-info--steps-text ol li {
    display: list-item;
    list-style-type: none;
    counter-increment: item;
    margin: 0.5em 0;
  }

  .migration-info--steps-text ol li:before {
    content: counter(item) ". ";
    font-weight: 700;
  }
</style>
