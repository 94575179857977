<template>
  <div class="profile-actions profile-actions--footer">
    <h2 class="form__title" role="title" :aria-label="$t('myaccount.delete-account.header')">
      {{ $t('myaccount.delete-account.header') }}
    </h2>
    <div class="lead-settings">
      <p>{{ $t('myaccount.delete-account.explanation') }}</p>
      <p v-if="user.isBosch" data-test-id="user-delete-account-bosch-notice" v-html="$t('myaccount.delete-account-in-ciam.explanation', {
        CIAM_PROFILE_URI: CIAM_PROFILE_URI
      })" role=""></p>
    </div>

    <router-link to="/account/delete" class="button button--ghost--tarmac delete-account-button spinning-button">
      {{ $t('myaccount.delete-account.button') }}
    </router-link>
  </div>
</template>

<script>
  import auth from '../common/auth';

  const { CIAM_PROFILE_URI } = require('../../config');

  export default {
    name: 'user-delete-account',
    data() {
      const { user } = auth;
      return {
        CIAM_PROFILE_URI,
        user,
      };
    },
  };

</script>

<style scoped>
.lead-settings {
  float: left;
  clear: both;
}
</style>
