/* eslint-disable */
import {createRouter, createWebHistory} from 'vue-router';
import myRoutes from './routes';
import commonRoutes from 'Common/router/routes';

export default createRouter({
  history: createWebHistory(),
  routes: commonRoutes.concat(myRoutes),
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
});
